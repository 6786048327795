.header {
  font-family: "Montserrat" !important;
  font-weight: 900;
  text-align: center;
  font-size: 6.6vh;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: -10vh;
  z-index: 1;
  line-height: 5vh;
  background-color: "white";
}
.header2 {
  font-family: "Raleway" !important;
  font-weight: 300;
  text-align: left;
  font-size: 2.3vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: -0.5vh;
  top: 0;
  z-index: 1;
  letter-spacing: 0.18em;
}
.scroll-downs {
  position: absolute;
  top: 60vh;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;

  z-index: 1;
  width: 34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid black;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: black;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.scrolling {
  position: absolute;
  top: 73vh;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;

  z-index: 1;
  font-family: "Raleway";
  font-weight: 300;
}
@media all and (min-device-width: 812px) and (max-device-width: 927px) and (orientation: landscape) {
  .scroll-downs {
    top: 80vh;
  }

  .scroller {
    height: 5px;
  }
  .scrolling {
    top: 80vh;
    left: 15vw;
  }
}
@media all and (device-width: 823px) and (device-height: 411px) and (orientation: landscape) {
  .scroll-downs {
    top: 80vh;
  }
  .scrolling {
    top: 90vh;
  }
}
@media only screen and (max-device-width: 1025px) and (orientation: landscape) {
  .mousey {
    border: none;
  }
}
