@import url("./sections/intro/stylesintro.css");
@import url("./sections/pink/stylespink.css");
@import url("./sections/green/stylesgreen.css");
@import url("./sections/orange/stylesorange.css");
@import url("./sections/blue/stylesblue.css");

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: white;
}
.cursor {
  cursor: url("./images/white.svg"), auto;
}
.cursorblue {
  cursor: url("./images/blue.svg"), auto;
}
svg {
  width: 100px;
  position: absolute;
  animation: rotation 4.5s infinite linear;
  pointer-events: none;
  transition: top 1s, left 1s;
  transition-timing-function: ease-out;
}

text {
  font-size: 4em;
  font-family: "Raleway";
  font-weight: 300;
  stroke: black;
  fill: white;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
  background-color: white;
}

.skeleton {
  background-color: rgba(240, 240, 240, 1);
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 500ms ease-in-out infinite;

  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
}
.title4 {
  position: absolute;
  width: 100%;
  left: -19em;
  top: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  /* cursor: url("./images/orange.svg"), auto; */
}

.title5 {
  position: absolute;
  width: 100%;
  left: -13em;
  top: -25vh;
  z-index: 1;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.title6 {
  position: absolute;
  width: 100%;
  left: 24em;
  top: -25vh;
  z-index: 1;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.title7 {
  position: absolute;
  width: 100%;
  left: 32em;
  top: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.seven {
  position: relative;
  border: solid #c73047 2px;
  width: 150px;
  height: 20px;
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  background-color: white;
}
.buttonmoda {
  width: 20vw;
  height: 30vh;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: url("./images/blue.svg"), auto;
}
.seven:before {
  content: "";
  position: absolute;
  width: 146px;
  height: 16px;
  top: 2px;
  left: 2px;
  background-color: #ffbbc4;
  border-radius: 4px;
  animation: loader7AnimationBefore 2s linear infinite;
}

.seven:after {
  content: "Loading...";
  position: absolute;
  width: 150px;
  color: #de828f;
  top: -7vh;
  left: -7%;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 1.5vw;
  text-align: center;
  animation: loader7AnimationAfter 2s linear infinite;
}

@keyframes loader7AnimationBefore {
  0% {
    width: 0%;
  }
  15% {
    width: 0%;
  }
  85% {
    width: 146px;
  }
  100% {
    width: 146px;
  }
}

@keyframes loader7AnimationAfter {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
  100% {
    content: "Loading...";
  }
}
.buttonthankloop {
  width: 29vw;
  height: 39vh;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: url("./images/blue.svg"), auto;
}
.buttonacad {
  width: 16vw;
  height: 12vh;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: url("./images/blue.svg"), auto;
}
.buttonbuzzle {
  width: 30vw;
  height: 26vh;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: url("./images/blue.svg"), auto;
}
.phone {
  height: 50px;
  width: 100px;
  border: 3px solid #c73047;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  font-size: 1em;
  margin-top: 40px;
  font-family: "Raleway";
  font-weight: 300;
  color: #de828f;
  /* display: none; */
}
.rotate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-device-width: 1025px) and (orientation: portrait) {
  .phone,
  .message {
    display: block;
  }
  .titlesportrait {
    display: none;
  }
}
@media only screen and (max-device-width: 1023px) and (orientation: landscape) {
  .phone,
  .message {
    display: none;
  }
  .title4 {
    left: -9em;
  }
  .title5 {
    left: -5em;
  }
  .title6 {
    left: 9em;
  }
  .title7 {
    left: 13em;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1100px) and (orientation: landscape) {
  .phone,
  .message {
    display: none;
  }
  .title4 {
    left: -17em;
  }
  .title5 {
    left: -11em;
  }
  .title6 {
    left: 20em;
  }
  .title7 {
    left: 26em;
  }
}
@media only screen and (min-device-width: 1025px) {
  .phone,
  .message {
    display: none;
  }
}
/* iPad with portrait orientation.*/

.image-background {
  display: block;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  position: static;
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .image-background {
    height: 768px;
    width: 120%;
    left: -100px;
    position: absolute;
  }
}
@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .image-background {
    height: 1024px;
    width: 115%;
    position: absolute;
    left: -100px;
  }
}
@media all and (device-width: 720px) and (device-height: 540px) and (orientation: landscape) {
  .image-background {
    height: 540px;
    width: 115%;
    position: absolute;
    left: -50px;
  }
}
@media all and (device-width: 1280px) and (device-height: 800px) and (orientation: landscape) {
  .image-background {
    height: 800px;
  }
}
@media all and (device-width: 1024px) and (device-height: 600px) and (orientation: landscape) {
  .image-background {
    height: 600px;
  }
}
@media all and (device-width: 653px) and (device-height: 280px) and (orientation: landscape) {
  .image-background {
    height: 280px;
    width: 100%;
  }
}
@media all and (device-width: 717px) and (device-height: 512px) and (orientation: landscape) {
  .image-background {
    height: 512px;
    width: 110%;
    position: absolute;
    left: -30px;
  }
}
