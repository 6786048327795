.thanks {
  font-family: "Raleway";
  font-weight: "bold";
  text-align: center;
  font-size: 11vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: -10vh;
  z-index: 1;
  padding-bottom: 5vh;
  margin: 0;
  -webkit-text-stroke: 5px #134084;
  color: #98b6e2;
}
.scroll {
  font-family: "Raleway";
  font-weight: 300;
  text-align: center;
  font-size: 3.5vh;
  align-items: center;
  display: flex;

  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 58vh;
  padding: 0;
  margin: 0;
  z-index: 1;

  color: #134084;
}
@media only screen and (max-device-width: 1025px) and (orientation: landscape) {
  .logos {
    width: 30%;
    height: 30%;
  }
  .thanks {
    -webkit-text-stroke: 3px #134084;
  }
}
