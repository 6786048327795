.header4 {
  font-family: "Raleway";
  font-weight: 400;
  text-align: center;
  font-size: 3.5vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0vh;
  top: -45vh;
  z-index: 1;
}
.education {
  font-family: "Montserrat" !important;
  font-weight: 800;
  text-align: center;
  font-size: 3vh;

  color: white;
}
.img1 {
  top: 53vh;
  position: absolute;
}
.img3 {
  top: 53vh;
  position: absolute;
}
.media {
  position: absolute;
  top: 29em;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 2vh;
}
.swe {
  position: absolute;
  top: 30em;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 2vh;
}
.img2 {
  top: 53.5vh;
  position: absolute;
}
@media only screen and (max-device-width: 1025px) and (orientation: landscape) {
  .img1 {
    width: 9%;
    height: 9%;
  }
  .img2 {
    width: 7%;
    height: 9%;
  }
  .img3 {
    width: 10%;
    height: 6%;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1100px) and (orientation: landscape) {
  .swe {
    top: 32em;
  }
  .media {
    top: 30em;
  }
}
