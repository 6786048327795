.header5 {
  font-family: "Raleway" !important;
  font-weight: 400;
  text-align: center;
  font-size: 3vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0vh;
  top: 42vh;
  z-index: 1;
}
.moda {
  position: absolute;
  left: 46%;
  top: 17%;
}
.acad {
  position: absolute;
  left: 24%;
  top: 18%;
}
.buzzle {
  position: absolute;
  left: 73%;
  top: 48%;
  z-index: 1;
}
.thankloop {
  position: absolute;
  left: 40%;
  top: 52%;
  z-index: 1;
}
.modal {
  padding: 32px;
}
@media only screen and (max-device-width: 1025px) and (orientation: landscape) {
  .image-modal {
    width: 50%;
    height: 50%;

    padding-left: 30px;
  }
  .image-modal-zara {
    width: 50%;
    height: 50%;

    padding-left: 90px;
  }
  .modal {
    padding: 10px;
  }
}
