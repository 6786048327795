.header3 {
  font-family: "Raleway";
  font-weight: 300;
  text-align: left;
  font-size: 2.5vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0vh;
  top: 8.5vh;
  z-index: 1;
  letter-spacing: 0.1em;
}
@media all and (device-width: 823px) and (device-height: 411px) and (orientation: landscape) {
}
@media all and (min-device-width: 812px) and (max-device-width: 927px) and (orientation: landscape) {
}
